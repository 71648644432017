import React from "react";
import Layout from "../components/Layout";
import AboutPage from "../components/AboutPage";
import { graphql } from "gatsby";
import MyHelmet from "../context/MyHelmet";

export default ({ data: { wpPage } }) => {
  return (
    <div>
      <MyHelmet page="about" language="en" meta={wpPage.seo} />
      <Layout>
        <AboutPage content={wpPage} />
      </Layout>
    </div>
  );
};

export const query = graphql`
  {
    wpPage(id: { eq: "cG9zdDoxMTQ3" }) {
      title
      content
      aboutUs {
        aboutUsPerson {
          email
          fieldGroupName
          jobTitle
          languages
          name
          phone
          text
          photo {
            sourceUrl
            title
          }
        }
        aboutUsTitle
        fieldGroupName
        historyEvents {
          fieldGroupName
          text
          year
        }
        historyIntro
        historyTitle
        howDoesItWorkBullets {
          bullet
          fieldGroupName
        }
        howDoesItWorkTitle
        orderButton
        orderQuoteButton
        services {
          fieldGroupName
          text
          title
          icon {
            sourceUrl
            title
          }
        }
        servicesTitle
        theyTrustedUsIcons {
          fieldGroupName
          logo {
            title
            sourceUrl
          }
        }
        theyTrustedUsTitle
      }
      seo {
        metaDesc
        title
      }
    }
  }
`;
